export const ADMIN_ROUTE = '/admin'
export const ABOUT_ROUTE = '/about'
export const DELIVERY_ROUTE = '/delivery'
export const LOGIN_ROUTE = '/login'
export const REGISTRATION_ROUTE = '/registration'
export const SHOP_ROUTE = '/device'
export const HOME_ROUTE = '/'
export const BASKET_ROUTE = '/basket'
export const DEVICE_ROUTE = '/device'
export const ORDER_ROUTE = '/order'
export const PROFILE_ROUTE = '/profile'
export const EMAIL = 'koolchitsky@gmail.com'