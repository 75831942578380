//menu for futor
/* import { useTranslation } from "react-i18next"

const {t} = useTranslation() */

export const scheduleRU = [
  { id: 0, info: 'Пн - Сб: 09:00 - 18:00' },
  { id: 1, info: 'Обед: 13:00 - 14:00' },
  { id: 2, info: 'Выходной: Воскресенье' },
]
export const scheduleUA = [
  { id: 0, info: 'Пн - Сб: 09:00 - 18:00' },
  { id: 1, info: 'Обід: 13:00 - 14:00' },
  { id: 2, info: 'Вихідний: Неділя' },
]
export const scheduleEN = [
  { id: 0, info: 'Mo - Sa: 09:00 - 18:00' },
  { id: 1, info: 'Break: 13:00 - 14:00' },
  { id: 2, info: 'Playday: Su' },
]

export const phone = [
  { id: 0, info: <a href="tel: +380680000000">+380680000000</a> },
  { id: 1, info: <a href="tel: +380990000000">+380990000000</a> },
]

export const aboutRU = [
  { id: 0, info: '69000' },
  { id: 1, info: 'г. Запорожье' },
  { id: 2, info: 'пр. Соборный, 300' },
]
export const aboutUA = [
  { id: 0, info: '69000' },
  { id: 1, info: 'м. Запоріжжя' },
  { id: 2, info: 'пр. Соборний, 300' },
]
export const aboutEN = [
  { id: 0, info: '69000' },
  { id: 1, info: 'Zaporizhzhya-city' },
  { id: 2, info: 'Sobornyi Avenue, 300' },
]