import { makeAutoObservable } from "mobx";

export default class DeviceStore {
  constructor() {
    this._types = []
    this._brands = []
    this._devices = []
    this._selectedType = []
    this._selectedBrand = []
    this._page = 1
    this._totalCount = 0
    this._limit = 10
    this._news = false
    this._discount = 1
    makeAutoObservable(this)
  }

  setTypes (types) {
    this._types = types
  }

  setBrands(brands) {
    this._brands = brands
  }

  setDevices(devices) {
    this._devices = devices
  }

  setSelectedType(type) {
    this._selectedType = type
  }

  setSelectedBrand(brand) {
    this._selectedBrand = brand
  }

  setPage(page) {
    this._page = page
  }

  setTotalCount(count) {
    this._totalCount = count
  }
  
  setLimit(limit) {
    this._limit = limit
  }

  setNews(news) {
    this._news = news
  }

  setDiscount(discount) {
    this._discount = discount
  }

  get types() {
    return this._types
  }

  get brands() {
    return this._brands
  }

  get devices() {
    return this._devices
  }

  get selectedType() {
    return this._selectedType
  }

  get selectedBrand() {
    return this._selectedBrand
  }

  get totalCount() {
    return this._totalCount
  }

  get page() {
    return this._page
  }

  get limit() {
    return this._limit
  }

  get news() {
    return this._news
  }

  get discount() {
    return this._discount
  }
}